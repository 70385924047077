import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from "react-router-dom"
import './App.css'
import MainLayout from './layouts/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './redux/actions/auth.actions';
import { CircularProgress } from '@mui/material';

const Login = lazy(() => import('./pages/Login'));
const Contact = lazy(() => import('./pages/Contact'));
const Products = lazy(() => import('./pages/Products'));
const EditProducts = lazy(() => import('./pages/EditProduct'));
const GalleryImages = lazy(() => import('./pages/GalleryImages'));
const Gallery = lazy(() => import('./pages/Gallery'));
const HomeCarousel = lazy(() => import('./pages/HomeCarousel'));

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: 'Jakarta Sans, sans-serif',
    },
    palette: {
      primary: {
        main: '#241E13', // Main color
        light: '#0da8db', // Light shade
        dark: '#08617f', // Dark shade
        contrastText: '#ffffff', // Text color for proper contrast
      },
      secondary: {
        main: '#C3D046', // Main color
        light: '#d9da6f', // Light shade
        dark: '#c7c934', // Dark shade
        contrastText: '#19053F', // Text color for proper contrast
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white', // Change the background color here
          },
        },
      },
    },
  });
  const dispatch = useDispatch()
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  React.useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);



  React.useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token') {

        dispatch(loadUser());
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);



  if (loading) {
    // You can show a loading spinner or component here
    return <div className='flex justify-center items-center h-screen'>
      <CircularProgress size={48} sx={{ color: '#0A7EA4' }} />
    </div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {isAuthenticated ? (
          <Route path='/' element={<MainLayout />}>
            <Route index element={<Navigate to='/dashboard' />} />

            <Route path='dashboard/contact' element={renderLazyComponent(Contact)} />
            <Route path='dashboard/products' element={renderLazyComponent(Products)} />
            <Route path='dashboard/products/:id' element={renderLazyComponent(EditProducts)} />
            <Route path='dashboard/gallery/:id' element={renderLazyComponent(GalleryImages)} />

            <Route path='dashboard/gallery' element={renderLazyComponent(Gallery)} />
            <Route path='dashboard/home' element={renderLazyComponent(HomeCarousel)} />

            {/* <Route path="dashboard/team/:teamId" element={<ViewTeam />} /> */}
            <Route path='*' element={<Navigate to='dashboard/home' />} />
          </Route>
        ) : (
          <>
            <Route path='/login' element={renderLazyComponent(Login)} />
            <Route path='*' element={<Navigate to='/login' />} />
          </>
        )}
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}

function renderLazyComponent(Component) {
  return (
    <Suspense fallback={<CircularProgress size={48} sx={{ color: '#0A7EA4' }} />}>
      <Component />
    </Suspense>
  );
}

export default App;
