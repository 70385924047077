import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import PublicIcon from '@mui/icons-material/Public';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, CircularProgress, Grid, Hidden, ListItemButton, ListItemIcon, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.svg'
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SearchIcon from '@mui/icons-material/Search';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/actions/auth.actions';
import { useSelector } from 'react-redux';
import ApiIcon from '@mui/icons-material/Api';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import PaymentIcon from '@mui/icons-material/Payment';
// import PaymentsIcon from '@mui/icons-material/Payments';
import { AttachMoneyOutlined, CalendarMonth, Groups2, LogoutOutlined, MoneyOffCsredOutlined, MoneyOutlined, PaymentOutlined } from '@mui/icons-material';
const drawerWidth = 280;

const App = () => {
    const location = useLocation()
    const theme = useTheme();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.auth.user)
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => ({
        root: {
            display: 'flex',
        },
        appBar: {
            backgroundColor: "white",

            width: isSmallScreen ? '100%' : 'calc(100% - 280px)'
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2),
        },
        logoImage: {
            marginTop: '30px',
            height: '80px',
            // width: 175
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingTop: 64

        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
        },
        listItemButton: {
            paddingLeft: 40,
            paddingRight: 40,
        },
        listItemText: {
            fontWeight: '500 !important', // Change the fontWeight to your desired value
        },
    }));

    const auth = useSelector(state => state.auth);

    const overrideStyles = {
        listItemButton: {
            paddingLeft: 5,
            paddingRight: 5,
            fontWeight: 600,
            '&:hover': {
                backgroundColor: '#fcddde', // Change this to the desired color
            },
            "&.Mui-selected": {
                backgroundColor: "#fcddde",
                "&:hover": {
                    backgroundColor: "#fcddde",
                },
            },

        },
        listItemIcon: {
            minWidth: 40
        }

    }

    const classes = useStyles();


    const [mobileOpen, setMobileOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [elevation, setElevation] = useState(0);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setElevation(4); // Set elevation value when scrolling
        } else {
            setElevation(0); // Reset elevation value when at the top
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);



    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} elevation={elevation}>
                <Toolbar>
                    {isSmallScreen &&
                        <>    <IconButton
                            color="primary"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                            <Typography component={Link} to='/dashboard' variant="h6" color='primary'>
                                Sinko
                            </Typography>
                        </>
                    }

                </Toolbar>
            </AppBar>
            <Hidden smDown>
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Link className={classes.logo} to='/dashboard' >
                        <img src={logo} alt="Logo" className={classes.logoImage} />
                    </Link >

                    <List sx={{ mt: 4 }}>
                        <ListItemButton key='Home' selected={location.pathname === '/dashboard/home'} component={Link} to='/dashboard/home' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Home" />
                        </ListItemButton>
                        {/* <ListItemButton key='Katalog' selected={location.pathname === '/dashboard/katalog'} component={Link} to='/dashboard/katalog' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Katalog" />
                        </ListItemButton>
                        <ListItemButton key='Katalog Downloads' selected={location.pathname === '/dashboard/katalog-downloads'} component={Link} to='/dashboard/katalog-downloads' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Katalog Downloads" />
                        </ListItemButton> */}
                        <ListItemButton key="Contact Leads" component={Link} selected={location.pathname === '/dashboard/contact'} to='/dashboard/contact' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Contact Leads" />
                        </ListItemButton>
                        <ListItemButton key='Products' selected={location.pathname === '/dashboard/products'} component={Link} to='/dashboard/products' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Products" />
                        </ListItemButton>
                        <ListItemButton key='Gallery' selected={location.pathname === '/dashboard/gallery'} component={Link} to='/dashboard/gallery' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Gallery" />
                        </ListItemButton>

                    </List>
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    className={classes.drawer}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                >
                    <Link className={classes.logo} to='/dashboard' >
                        <img src={logo} alt="Logo" className={classes.logoImage} />
                    </Link >

                    <List sx={{ mt: 4 }}>
                        <ListItemButton key='Home' selected={location.pathname === '/dashboard/home'} component={Link} to='/dashboard/home' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Home" />
                        </ListItemButton>
                        {/* <ListItemButton key='Katalog' selected={location.pathname === '/dashboard/katalog'} component={Link} to='/dashboard/katalog' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Katalog" />
                        </ListItemButton>
                        <ListItemButton key='Katalog Downloads' selected={location.pathname === '/dashboard/katalog-downloads'} component={Link} to='/dashboard/katalog-downloads' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Katalog Downloads" />
                        </ListItemButton> */}
                        <ListItemButton key="Contact Leads" component={Link} selected={location.pathname === '/dashboard/contact'} to='/dashboard/contact' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Contact Leads" />
                        </ListItemButton>
                        <ListItemButton key='Products' selected={location.pathname === '/dashboard/products'} component={Link} to='/dashboard/products' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Products" />
                        </ListItemButton>
                        <ListItemButton key='Gallery' selected={location.pathname === '/dashboard/gallery'} component={Link} to='/dashboard/gallery' sx={overrideStyles.listItemButton}>
                            <ListItemText classes={{ primary: classes.listItemText }} primary="Gallery" />
                        </ListItemButton>

                    </List>
                </Drawer>
            </Hidden>

            <main className={classes.content}>
                <Outlet />
            </main>
        </div>
    );
};

export default App;
